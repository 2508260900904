import React from 'react';
import { Box, Typography, Container, Card, CardContent } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MainPage from "./main"
import Partner from './Partner';
import Slider from './Slider';

const HomePage = () => {
  return (
    <div>
       
      <MainPage/>
      {/* <Partner/> */}

      
    </div>
  );
};

export default HomePage;
